<template>
  <v-container fluid>
    <interconnector-rules-script-table
        :headers="headers"
        :expanded-headers="expanded"
        :data="data"
        :loading="loading"
        :table-page="page"
        :table-page-total-count="pageTotalCount"
        :table-size="size"
        @change="loadData($event.page, $event.size)"
        @cancelDelete="cancelDelete($event)"
        @deleteRule="deleteRule($event)"
    />
  </v-container>
</template>

<script>
import interconnectorRulesScriptTable from "./script/table";
import { Errors } from "@/utils/error";
import {
  getScriptRules,
  deleteScriptRule,
} from "@/api/interconnector/ruleScript";
export default {
  components: {
    interconnectorRulesScriptTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    interconnectorRulesScriptTable: {
      get() {
        return this.$store.state.tables.interconnectorRulesScriptTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "interconnectorRulesScriptTable",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "rule-script") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
    interconnectorRulesScriptTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getScriptRules(newPage, size)
          .then((response) => {
            this.loading = false;
            this.data = response.data;
            this.data.forEach(function (value) {
              value.resetMenu = false;
              value.deleteMenu = false;
              value.resetLoading = false;
            });
            this.pageTotalCount = response.last_page;
            this.page = response.current_page;
            this.size = parseInt(response.per_page);
            this.sizeTotalCount = response.total;
          })
          .catch((e) => {
            Errors.show(e);
            this.loading = false;
          });
    },
    cancelDelete(item) {
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
    },
    deleteRule(item) {
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
      deleteScriptRule(item.id)
          .then((response) => {
            if (response.type === "success") {
              this.$toast.open({
                message: "Rule was successfully deletes!",
                type: "success",
                position: "top-right",
                duration: 2000,
              });
            } else {
              Errors.show(response);
            }
            this.loadData(this.page, this.size);
          })
          .catch((e) => {
            Errors.show(e);
            const data = [];
            this.data.forEach(function (value) {
              if (item.id === value.id) {
                value.resetLoading = false;
              }
              data.push(value);
            });
            this.data = [];
            this.$nextTick(() => {
              this.data = data;
            });
          });
    },
  },
};
</script>
